<template>
  <div class="right">
    <span class="spen">授信额度</span>
    <div class="top">
      <div class="money">
        <span>待还额度</span>
        <div class="jine">
          <div class="num">
            ￥{{
              (daihuan.creditRepaymentLine = daihuan.creditRepaymentLine
                ? Number(daihuan.creditRepaymentLine).toFixed(2)
                : 0)
            }}
          </div>
        </div>

        <div class="process">
          <div class="yellow" v-bind:style="{ width: health + '%' }"></div>
        </div>
        <div class="keyong">
          <div class="keyong1">可用额度</div>
          <div class="keyong2">
            ￥{{
              (daihuan.creditsurplus = daihuan.creditsurplus
                ? Number(daihuan.creditsurplus).toFixed(2)
                : 0)
            }}
          </div>
          <div class="keyong3">
            总额度 ￥{{
              (daihuan.creditLine = daihuan.creditLine
                ? Number(daihuan.creditLine).toFixed(2)
                : 0)
            }}
          </div>
        </div>
      </div>
      <div class="xinyongfen">
        <span>已用额度</span>
        <div class="jindu">
          <div class="circle-container">
            <circle1
              :percent="health"
              :stroke-width="6"
              :trail-width="6"
              stroke-color="#1ba7fc"
              trail-color="#e3e9ff"
            />
          </div>
          <div class="posi">
            <div class="fen">{{ health }}%</div>
            <div class="tit">
              总额度：￥{{
                (daihuan.creditLine = daihuan.creditLine
                  ? Number(daihuan.creditLine).toFixed(2)
                  : 0)
              }}
            </div>
            <div class="tit">
              可用额度：￥{{
                (daihuan.creditsurplus = daihuan.creditsurplus
                  ? Number(daihuan.creditsurplus).toFixed(2)
                  : 0)
              }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="con">
      <div class="qiehuan">
        <div
          class="daohang"
          :class="[
            { bian: index == TabIndex },
            { 'border-right-line': index != TabList.length - 1 },
          ]"
          v-for="(item, index) in paihang"
          :key="index"
          @click="selectedTabItem(index, item.id)"
        >
          {{ item }}
        </div>
      </div>
      <div class="zhangdan1" v-if="TabIndex == 0">
        <div v-for="(item, index) in yichu" :key="index">
          <el-timeline>
            <el-timeline-item :timestamp="item.createTime" placement="top">
              <el-card>
                <h4>
                  账单金额：￥{{ Number(item.creditRepaymentLine).toFixed(2) }}
                </h4>
                <p>
                  记账周期：{{ item.statementDate }}至{{ item.repaymentDate }}
                </p>
                <p>出账日期：{{ item.createTime }}</p>
              </el-card>
            </el-timeline-item>
          </el-timeline>
        </div>
      </div>
      <div class="zhangdan1" v-if="TabIndex == 1">
        <div v-for="(res, i) in weichu" :key="i">
          <el-timeline>
            <el-timeline-item :timestamp="res.createTime" placement="top">
              <el-card>
                <h4>
                  账单金额：￥{{ Number(res.creditRepaymentLine).toFixed(2) }}
                </h4>
                <p>
                  记账周期：{{ res.statementDate }}至{{ res.repaymentDate }}
                </p>
                <p>出账日期：{{ res.createTime }}</p>
              </el-card>
            </el-timeline-item>
          </el-timeline>
          <div class="alldaihuan">
            全部代还金额：￥{{ Number(res.creditRepaymentLine).toFixed(2) }}
          </div>
        </div>
      </div>
      <div v-if="TabIndex == 2">
        <el-table :data="tableData" style="width: 100%">
          <el-table-column prop="creditRepaymentLine" label="还款金额">
          </el-table-column>
          <el-table-column prop="createTime" label="还款时间">
          </el-table-column>
          <el-table-column prop="fangshi" label="支付方式"> </el-table-column>
          <el-table-column prop="danhao" label="还款单号"> </el-table-column>
          <el-table-column prop="creditRepaymentType" label="还款状态">
          </el-table-column>
        </el-table>
      </div>
      <div class="fenye">
        <el-pagination
          @current-change="pagechange"
          :current-page="page.current"
          style="text-align: right;margin-top: 20px"
          background
          :total="page.total"
          :page-size="page.size"
          layout="prev, pager, next, jumper, ->, total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import circle1 from '@/components/order/zichan/xinyong.vue';
import Header from '@/components/header.vue';
import { post, get } from '@/utils/request';
import {formatDecimal} from '@/utils/numberUtils.js';

export default {
  components: {
    Header,
    circle1,
  },
  data() {
    return {
      percent: 93,
      health: 0,
      TabIndex: 0, // 默认选中Tab
      TabList: [], // Tab数据集合
      paihang: ['已出账单', '未出账单', '还款流水'],
      tableData: [],
      daihuan: [],
      yichu: [],
      weichu: [],
      liushui: [],
      page: {
        total: 1,
        size: 10,
        current: 1,
      },
    };
  },
  created() {
    this.getedu();
    this.getyichu();
    this.getweichu();
    this.getliushui();
  },
  methods: {
    getedu() {
      get('api/credit/getcreditnum').then((res) => {
        if (res.data.data) {
          this.daihuan = res.data.data;
          this.health =formatDecimal((res.data.data.creditRepaymentLine / res.data.data.creditLine) * (100),2);
        }
      });
    },
    getyichu() {
      get('api/credit/getcreditvo').then((res) => {
        this.yichu = res.data.data;
        res.data.data.forEach((req) => {
          req.statementDate = req.statementDate.substring(0, 10);
          req.repaymentDate = req.repaymentDate.substring(0, 10);
          req.createTime = req.createTime.substring(0, 10);
        });
      });
    },
    getweichu() {
      get('api/credit/getnocreditvo').then((res) => {
        this.weichu = res.data.data;
        res.data.data.forEach((req) => {
          req.statementDate = req.statementDate.substring(0, 10);
          req.repaymentDate = req.repaymentDate.substring(0, 10);
          req.createTime = req.createTime.substring(0, 10);
        });
      });
    },
    getliushui() {
      get('api/credit/getrepayflow?pageNum=1&pageSize=10').then((res) => {
        this.page.total = res.data.data.total;
        this.tableData = res.data.data.list;
        res.data.data.list.forEach((req) => {
          if (req.creditRepaymentType == 1) {
            req.creditRepaymentType = '已还款';
          }
          req.createTime = req.createTime.substring(0, 10);
        });
      });
    },
    pagechange(p) {
      this.page.current = p;
      get('api/credit/getrepayflow?pageNum=' + p + '&pageSize=10').then(
        (res) => {
          this.tableData = res.data.data.list;
        }
      );
    },
    selectedTabItem(index, e) {
      this.TabIndex = index;
    },
  },
};
</script>
<style lang="scss" scoped>
.right {
  width: 976px;
  padding-top: 34px;
  padding-left: 27px;
  background: #fff;
  padding-right: 30px;
  .spen {
    font-size: 26px;
    font-weight: 600;
    color: #616161;
  }
  .top {
    display: flex;
    margin-top: 40px;
    .money {
      width: 590px;
      height: 365px;
      border-right: 2px solid #f0f0f0;
      span {
        font-size: 15px;
        color: #343434;
        font-weight: 600;
      }
      .jine {
        display: flex;
        margin-top: 36px;
        position: relative;
        .num {
          font-size: 45px;
          color: #767676;
        }
        .huan {
          position: absolute;
          right: 88px;
          top: 11px;
          width: 112px;
          height: 37px;
          background: #1850ff;
          border-radius: 5px;
          color: #fff;
          font-size: 16px;
          text-align: center;
          line-height: 37px;
        }
      }
      .process {
        margin-top: 20px;
        width: 290px;
        height: 11px;
        background: #e6e6e6;
        .yellow {
          height: 11px;
          background: #ff902c;
        }
      }
      .daihuan {
        color: #707070;
        margin-top: 18px;
        font-size: 13px;
        span {
          font-size: 16px;
          color: #666666;
        }
      }
      .keyong {
        margin-top: 48px;
        width: 287px;
        height: 125px;
        border: 2px solid #e4e4e4;
        .keyong1 {
          font-size: 15px;
          color: #333;
          font-weight: 600;
          margin-top: 15px;
          text-align: center;
        }
        .keyong2 {
          text-align: center;
          font-size: 24px;
          color: #767676;
          font-weight: 600;
        }
        .keyong3 {
          text-align: center;
          color: #666;
          margin-top: 11px;
        }
      }
    }
    .xinyongfen {
      padding-left: 80px;
      span {
        font-size: 15px;
        color: #343434;
        font-weight: 600;
      }
      .jindu {
        position: relative;
        .circle-container {
          margin-top: 63px;
          margin-left: 20px;
          width: 214px;
          height: 214px;
          transform: rotate(-135deg);
          -ms-transform: rotate(-135deg); /* Internet Explorer */
          -moz-transform: rotate(-135deg); /* Firefox */
          -webkit-transform: rotate(-135deg); /* Safari 和 Chrome */
          -o-transform: rotate(-135deg); /* Opera */
        }
        .posi {
          position: absolute;
          z-index: 10;
          top: 60px;
          left: 40px;
          width: 170px;
          .fen {
            font-size: 40px;
            color: #ff8f29;
            font-weight: 600;
            text-align: center;
          }
          .tit {
            text-align: center;
            font-size: 13px;
            color: #ff8f29;
          }
        }
      }
    }
  }
  .con {
    margin-top: 97px;
    .qiehuan {
      margin-top: 60px;
      height: 42px;
      display: flex;
      align-items: center;
      cursor: default;
      .daohang {
        font-size: 15px;
        color: #272727;
        margin-right: 25px;
        cursor: pointer;
      }
      .bian {
        color: #1a4fff;
      }
    }
    .zhangdan1 {
      padding: 20px 0;
      .alldaihuan {
        margin-left: 65px;
        font-size: 18px;
        color: #333;
      }
    }
  }
}
</style>
